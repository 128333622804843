import axios from "axios";
import { getUserData } from "common/getUserData";
const BASE_URL =
process.env.REACT_APP_EDOC_API_URL || "";
export default function getAxiosInstance(link, method, payload, headers) {
  let userData = getUserData();
  let requestObj = {
    method: method,
    url: `${BASE_URL}/${link}`,
    data: payload,
    headers: {
      Authorization: `Bearer ${userData?.accessToken}`,
    },
  };
  return axios(requestObj)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // if (link !== "auth/login") {
      //   if (err?.response?.status === 401) {
      //     window.location.href = "/login";
      //   }
      // }
      return err;
    });
}
