// material-ui
import { Button, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import { gridSpacing } from 'store/constant';
import TableCard from './TableCard';

// assets
import getAxiosInstance from 'api';
import { useEffect, useState } from 'react';

// ==============================|| ANALYTICS DASHBOARD ||============================== //

const Business = () => {
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const [column, setColumn] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState('ASC');
  const [orderBy, setOrderBy] = useState('id');
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const columnList = [
      'ID',
      'Business Partner Name ',
      'Primary User Email',
      'No of Secondary User',
    ];
    setColumn(columnList);
    const payload = {
      page: page + 1,
      limit: limit,
      sortBy: orderBy,
      sortDirection: order,
      search: search,
      filter: {
        partnerType: 'API',
        isActive: true,
      },
    };
    getAxiosInstance(
      'adminOrchestrator/businessPartner/list',
      'post',
      payload
    ).then((res) => {
      if (res?.data?.data) {
        setRows(res?.data?.data?.items);
        setTotal(res?.data?.data?.total || 0);
      }
    });
  }, [page, limit, order, orderBy, search]);
  const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));

  const blockSX = {
    p: 2.5,
    borderLeft: '1px solid ',
    borderBottom: '1px solid ',
    borderLeftColor:
      theme.palette.mode === 'dark'
        ? theme.palette.dark.main
        : theme.palette.grey[200],
    borderBottomColor:
      theme.palette.mode === 'dark'
        ? theme.palette.dark.main
        : theme.palette.grey[200],
  };

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <TableCard
          title="Customers"
          rowsData={rows}
          column={column}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          order={order}
          setOrder={setOrder}
          search={search}
          setSearch={setSearch}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          total={total}
        />
      </Grid>
    </Grid>
  );
};

export default Business;
