import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import { Navigate } from "react-router-dom";
import ApiBusinessPartner from "views/api-business-partner";
import SampleResponse from "views/SampleResponse";
import ApproveApp from 'views/api-business-partner/appApprove';
import ApproveBusinessApp from 'views/dashboard/Business/approveBusinessApp';

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);
const DashboardAnalytics = Loadable(
  lazy(() => import("views/dashboard/Analytics"))
);
const DashboardBusiness = Loadable(
  lazy(() => import("views/dashboard/Business"))
);
const Reports = Loadable(lazy(() => import("views/reports")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/dashboard',
      element: <DashboardDefault />,
    },
    {
      path: '/customer-dashboard',
      element: <DashboardAnalytics />,
    },
    {
      path: '/business-dashboard',
      element: <DashboardBusiness />,
    },
    {
      path: '/business-dashboard/app-approve',
      element: <ApproveBusinessApp />,
    },
    {
      path: '/reports',
      element: <Reports />,
    },
    {
      path: '/api-business-partner',
      element: <ApiBusinessPartner />,
    },
    {
      path: '/api-business-partner/app-approve',
      element: <ApproveApp />,
    },

    {
      path: '/sample-bank-response',
      element: <SampleResponse />,
    },
    { path: '*', element: <Navigate to="/dashboard" replace /> },
  ],
};

export default MainRoutes;
